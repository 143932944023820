<form [formGroup]="conclusionForm">
  <div>
    <mat-checkbox formControlName="signerJuristicPerson" id="checkbox_is-juristic-person">Ich habe den Unterzeichner
      identifiziert. Das Original des Ausweisdokumentes, welches mir die anwesende natürliche
      Person vorgelegt hat, habe ich vollständig und lesbar fotografiert.*</mat-checkbox>
  </div>
  <hr>
  <div>
    <mat-checkbox formControlName="registerInformationConfirmation" id="checkbox_transparency-information-confirmation">
        Der Kunde ist darüber informiert, dass er als Unternehmen (juristische Personen des Privatrechts und eingetragene Personengesellschaften)
        gemäß Geldwäschegesetz verpflichtet ist, Angaben zu seinen wirtschaftlich Berechtigten zum Transparenzregister zu melden.*
    </mat-checkbox>
  </div>
  <hr>
  <div>
    <mat-radio-group formControlName="transparencyRegistryNotification" color="primary" id="radio_need-for-transparency">
      <mat-radio-button value="missing">
          Der Kunde erklärte, dass er für sein Unternehmen noch keine Meldung zum Transparenzregister vorgenommen hat.
      </mat-radio-button>
      <mat-radio-button value="entry" id="radio_no-need-for-transparency">
        Der Kunde erklärte, dass er für sein Unternehmen eine Meldung zum
        Transparenzregister vorgenommen habe und eine Eintragung somit vorliege.
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <hr>
</form>
