/* libs/legacy/gwg/src/lib/confirmation/confirmation/confirmation.component.scss */
:host {
  display: block;
  width: 100%;
  color: #5e6f79;
}
:host p {
  margin-bottom: 25px;
}
:host .gwg-confirmation-container {
  margin-top: 24px;
  padding: 0 24px;
}
:host .checkbox-container {
  display: flex;
}
/*# sourceMappingURL=confirmation.component.css.map */
