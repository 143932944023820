import { IObjectConditionDto, IObjectDto } from '@abcfinlab/api/global';
import { UsedCondition } from '../models/enums/UsedCondition.enum';
import { LeasingQuoteObjectDTO } from '../models/LeasingQuoteObjectDTO.interface';
import { RemoteObjectDTO } from '../models/RemoteObjectDTO.interface';

export function hasUsedCondition(objectProperty: Partial<RemoteObjectDTO | Array<LeasingQuoteObjectDTO> | Array<IObjectDto>>) {
    if (typeof objectProperty === 'undefined' || objectProperty === null) {
        throw new Error('No Leasing-Quote Object(s) provided!');
    }
    if (Array.isArray(objectProperty)) {
        return objectProperty.some(object => object.condition === UsedCondition.USED);
    } else {
        return objectProperty.condition === UsedCondition.USED;
    }
}

/**
 * View- / Form-Helper to patch a form field based on the given condition
 * @todo  Translate implementation
 * @param condition   UsedCondition
 */
export function mapConditionToText(condition: UsedCondition | IObjectConditionDto): string {
    if (condition === UsedCondition.NEW) {
        return 'Fabrikneu';
    }
    if (condition === UsedCondition.USED) {
        return 'Gebraucht';
    }
    return 'INCOMING FEATURE';
}
