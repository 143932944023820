<div class="gwg-confirmation-container">
  <p>{{ 'gwg.confirmation.agreement' | translate }}</p>
  <form [formGroup]="confirmationGroup">
    <div class="checkbox-container">
      <mat-checkbox [name]="'checkbox_GDPR'" id="checkbox_GDPR-agreement" formControlName="gdprConfirmation">
        {{ 'gwg.confirmation.gdpr_agreement_part1' | translate }} <a (click)="openDocumentViewer(options?.GDPR, $event)" rel="noopener noreferrer" id="anchor_download-gdpr">
          {{ 'gwg.confirmation.gdpr_agreement_link' | translate }}</a>{{ 'gwg.confirmation.gdpr_agreement_part2' | translate }}
      </mat-checkbox>
    </div>
    <div class="checkbox-container">
        <mat-checkbox formControlName="agbConfirmation"
                      [name]="'checkbox_AGB'"
                      id="checkbox_AGB-agreement">
            <a (click)="openDocumentViewer(options?.AGB, $event)" rel="noopener noreferrer" id="anchor_download-agb">{{ 'gwg.confirmation.agb_agreement_link' | translate }}</a>
            <ng-container *ngIf="contractType !== 'MIETKAUF' && contractType !== 'MKN'; else isMietkauf">
                {{ 'gwg.confirmation.agb_agreement_part2' | translate }}
            </ng-container>
            <ng-template #isMietkauf>
                {{ 'gwg.confirmation.agb_agreement_part2_mk' | translate }}
            </ng-template>
        </mat-checkbox>
    </div>
    <div class="checkbox-container" *ngIf="condition === 'USED'">
      <mat-checkbox [name]="'checkbox_EOW'" id="checkbox_accept-eow" formControlName="eowConfirmation">
          <a (click)="openDocumentViewer(options?.EOW, $event)"rel="noopener noreferrer"
             id="anchor_download-eow">{{ 'gwg.confirmation.eow_agreement' | translate }}</a>{{ 'gwg.confirmation.accept' | translate }}
      </mat-checkbox>
    </div>
    <div class="checkbox-container">
      <mat-checkbox [name]="'checkbox_SEPA'" id="checkbox_SEPA-agreement" formControlName="sepaConfirmation">
        {{ 'gwg.confirmation.sepa_agreement' | translate }}
      </mat-checkbox>
    </div>
    <div class="checkbox-container" *ngIf="(hasSlbConfirmation | async)">
      <mat-checkbox [name]="'checkbox_SLB'" id="checkbox_SLB-agreement" formControlName="slbConfirmation">
          <a (click)="openDocumentViewer(options?.SLB, $event)" rel="noopener noreferrer"
             id="anchor_download-slb">{{ 'gwg.confirmation.slb_agreement' | translate }}</a>{{ 'gwg.confirmation.accept' | translate }}
      </mat-checkbox>
    </div>
  </form>
</div>
