<l7-confirmation-summary [quoteSummary]="quoteSummary"></l7-confirmation-summary>
<h3>Bitte bestätigen Sie die Checkboxen.</h3>
<form [formGroup]="standardConfirmationGroup"
      (ngSubmit)="submitConfirmations()"
      *ngIf="this.isDataAvailable">
  <div>
    <hr>
    <div class="checkbox-container">
      <mat-checkbox formControlName="gdprConfirmation"
                    id="checkbox_GDPR-agreement">
          {{ 'gwg.confirmation.gdpr_agreement_part1' | translate }} <a (click)="openDocumentViewer(privacypolicyFile, $event)" rel="noopener noreferrer" id="anchor_download-gdpr">
          {{ 'gwg.confirmation.gdpr_agreement_link' | translate }}</a>{{ 'gwg.confirmation.gdpr_agreement_part2' | translate }}
      </mat-checkbox>
    </div>
    <hr>
    <div class="checkbox-container">
      <mat-checkbox formControlName="agbConfirmation"
                    id="checkbox_AGB-agreement">
          <a (click)="openDocumentViewer(unsignedContractFile, $event)" rel="noopener noreferrer" id="anchor_download-agb">{{ 'gwg.confirmation.agb_agreement_link' | translate }}</a>
          <ng-container *ngIf="contractType !== 'MIETKAUF' && contractType !== 'MKN'; else isMietkauf">
              {{ 'gwg.confirmation.agb_agreement_part2' | translate }}
          </ng-container>
          <ng-template #isMietkauf>
              {{ 'gwg.confirmation.agb_agreement_part2_mk' | translate }}
          </ng-template>
      </mat-checkbox>
    </div>
    <hr>
    <ng-container *ngIf="showEOWConfirmation">
      <div>
        <mat-checkbox formControlName="eowConfirmation"
                      id="checkbox_accept-eow">
            <a (click)="openDocumentViewer(eowDocumentFile, $event)" rel="noopener noreferrer"
               id="anchor_download-eow">{{ 'gwg.confirmation.eow_agreement' | translate }}</a>{{ 'gwg.confirmation.accept' | translate }}
        </mat-checkbox>
      </div>
      <hr>
    </ng-container>
    <div>
      <mat-checkbox formControlName="sepaConfirmation"
                    id="checkbox_SEPA-agreement">
          {{ 'gwg.confirmation.sepa_agreement' | translate }}
      </mat-checkbox>
    </div>
    <ng-container *ngIf="hasSlbConfirmation | async">
      <hr>
      <div>
        <mat-checkbox formControlName="slbConfirmation"
                      id="checkbox_accept-slb">
            <a (click)="openDocumentViewer(slbDocumentFile, $event)" rel="noopener noreferrer"
               id="anchor_download-slb">{{ 'gwg.confirmation.slb_agreement' | translate }}</a>{{ 'gwg.confirmation.accept' | translate }}
        </mat-checkbox>
      </div>
    </ng-container>
  </div>
  <hr>
  <div class="align-center">
      <l7-spacer [thickness]="'all'" [multiplier]="24">
          <button mat-flat-button
                  [color]="'accent'"
                  id="to_signature_process"
                  type="submit"
                  [disabled]="!standardConfirmationGroup.valid">
              {{ 'Zur Vertragsunterschrift' | translate }}
          </button>
      </l7-spacer>
  </div>
</form>
