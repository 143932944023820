/* libs/legacy/gwg/src/lib/signature/signature/signature.component.scss */
h2 {
  text-transform: uppercase;
}
h2,
h3 {
  font-weight: normal;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5e6f79;
}
h2 {
  margin-bottom: 10px;
  margin-top: 30px;
}
h1,
h3 {
  margin-bottom: 30px;
}
.align-center {
  display: flex;
  justify-content: center;
}
.form-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
a {
  text-decoration: underline;
  font-weight: normal;
  color: #b81931;
  margin: 24px 0;
}
::ng-deep signature-pad canvas {
  border-style: dashed;
  border-width: 1px;
  max-width: 100%;
  min-height: 300px;
  background-color: #cdd3d7;
}
.background {
  min-height: 300px;
}
button {
  margin-bottom: 50px;
}
@media (max-width: 576px) {
  h1 {
    font-size: 1.8em;
  }
}
/*# sourceMappingURL=signature.component.css.map */
