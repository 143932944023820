export enum ConfirmationType {
    TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
    SALE_AND_LEASE_BACK = 'SALE_AND_LEASE_BACK',
    USED_CONDITION = 'USED_CONDITION',
    GDPR = 'GDPR',
    SEPA = 'SEPA',
    APPROVED_WITH_CONDITIONS = 'APPROVED_WITH_CONDITIONS',
    TRANSPARENCY_REGISTER_NOTIFICATION_MISSING = 'TRANSPARENCY_REGISTER_NOTIFICATION_MISSING',
    TRANSPARENCY_REGISTER_NOTIFICATION_ENTRY = 'TRANSPARENCY_REGISTER_NOTIFICATION_ENTRY',
    IDENTIFICATION_NATURAL_PERSON = 'IDENTIFICATION_NATURAL_PERSON',
    IDENTIFICATION_JURISTIC_PERSON = 'IDENTIFICATION_JURISTIC_PERSON',
    PERSON_ON_OWN_BEHALF = 'PERSON_ON_OWN_BEHALF',
}
