import { QuoteService, RemoteService } from '@abcfinlab/api/global';
import { AppConfig, TranslationFacade } from '@abcfinlab/core';
import { SignaturePadComponent } from '@abcfinlab/presentation';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as blobUtil from 'blob-util';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogService } from '../../../../../../../apps/l7/src/app/private/services/dialog/dialog.service';
import {
    GenericDialogComponent,
    GenericDialogData,
} from '../../../../../../../apps/l7/src/app/shared/modals/generic-dialog/generic-dialog.component';
import { PictureType } from '../../models/picture-type.enum';
import { LeasingQuoteIdService } from '../../services/leasing-quote-id.service';
import { PicturesService } from '../../services/pictures.service';
import {
    ContractDocumentCreationModalComponent,
} from '../../shared/contract-document-creation-modal/contract-document-creation.modal';

export interface SignaturePadOptions {
    minWidth: number;
    canvasWidth: number;
    canvasHeight: number;
}

@UntilDestroy()
@Component({
    selector: 'gwg-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss'],
    standalone: false,
})
export class SignatureComponent {

    @Input() isRemote: boolean;

    @Input() set signaturePadWidth(value: number) {
        if (value) {
            this.signaturePadOptions.canvasWidth = value;
        }
    }

    @Input() set signingLinkUui(value: string) {
        this._actionUUID = value;
    }

    @ViewChild('signaturePad') signaturePad: SignaturePadComponent;

    disabled: boolean = true;

    cityOfSignature: string = '';

    private canvasDirty: boolean = false;

    private inputDirty: boolean = false;

    private _actionUUID: string;

    private readonly data: FormData = new FormData();

    private dialogRef: MatDialogRef<ContractDocumentCreationModalComponent>;

    private _signatureBlob: Blob;

    private readonly _isIdentificationRequired = new BehaviorSubject<boolean>(null);

    private readonly _host: string;

    signaturePadOptions: SignaturePadOptions = {
        minWidth: 3,
        canvasWidth: 1000,
        canvasHeight: 402,
    };

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly picturesService: PicturesService,
        private readonly translationFacade: TranslationFacade,
        private readonly dialogService: DialogService,
        private readonly datePipe: DatePipe,
        private readonly _leasingQuoteService: LeasingQuoteIdService,
        private readonly _remoteService: RemoteService,
        private readonly _quoteService: QuoteService,
        private readonly _http: HttpClient,
        public dialog: MatDialog,
        appConfig: AppConfig,
    ) {
        this._host = appConfig.get('host');
        this._leasingQuoteService.isIdentificationRequired.pipe(untilDestroyed(this))
            .subscribe(x => this._isIdentificationRequired.next(x));
    }

    confirmContract(): void {
        this.disabled = true;

        if (!this.isRemote && !this._isIdentificationRequired.getValue()) {
            this.data.set('idBack', new Blob([JSON.stringify(null)], { type: 'application/json' }));
            this.data.set('idFront', new Blob([JSON.stringify(null)], { type: 'application/json' }));
        }

        if (this.isRemote || !this._isIdentificationRequired.getValue()) {
            this.sendDataToBackend();
            return;
        }

        const getDocumentFront$ = this.picturesService.getIdentificationDocument(PictureType.Frontside).pipe(
            map((frontSideDocument: string) => {
                const frontSideFileExtension = this.picturesService.getDocumentFileExtension(frontSideDocument);
                this.data.set('idFront', blobUtil.dataURLToBlob(frontSideDocument), `idFront${frontSideFileExtension}`);
            }),
        );
        const getDocumentBack$ = this.picturesService.getIdentificationDocument(PictureType.Backside).pipe(
            map((backSideDocument: string) => {
                const backSideFileExtension = this.picturesService.getDocumentFileExtension(backSideDocument);
                this.data.set('idBack', blobUtil.dataURLToBlob(backSideDocument), `idBack${backSideFileExtension}`);
            }),
        );

        const prepareDocuments$ = zip(getDocumentFront$, getDocumentBack$);

        prepareDocuments$
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.sendDataToBackend();
            }, error => alert('ID Dateien konnten nicht gelesen werden!'));
    }

    sendDataToBackend() {
        let createContract: Observable<any>;
        if (this.isRemote) {
            const dataPath = `${this._host}/api/v1/remote/signature`;
            this.data.set('action', this._actionUUID);
            this.data.set('city', this.cityOfSignature);

            createContract = this._http.post<void>(dataPath, this.data);

            // @TODO this signature api is not working we need to refactor because city and action are not passed or not read by BE
            /* this._remoteService.uploadSignatureAndSendMails({
                city: this.cityOfSignature,
                action: this._actionUUID,
                body: { signature: this._signatureBlob, city: this.cityOfSignature, action: this._actionUUID } as unknown as { signature: Blob }
            }); */
        } else {
            createContract = this._quoteService.submitLeasingQuote({
                city: this.cityOfSignature,
                leasingQuoteId: this._leasingQuoteService.getLeasingQuoteId(),
                body: this.data as unknown as { idFront: Blob; idBack: Blob; signature: Blob },
            });
        }

        this.openDialog();
        createContract.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.dialog.closeAll();
                this.picturesService.clearDocumentStorage()
                    .pipe(untilDestroyed(this))
                    .subscribe();
                void this.router.navigate(['../success'], { relativeTo: this.route }).then();
            }, (err) => {
                if (err instanceof HttpErrorResponse && err.status === 409 && err.error.error === 'money_laundring_invalid' && err.error.error === 'beneficial_owner_missing') {
                    const data: GenericDialogData = {
                        id: 'dialog_money_laundring_invalid',
                        image: 'assets/images/image-failure.svg',
                        body: `dialogs.${err.error.error}`,
                        positiveText: 'global.close',
                    };
                    this.dialogService.openDialog(GenericDialogComponent, { minHeight: undefined }, data).afterClosed().subscribe(() => {
                        this.dialog.closeAll();
                    });
                } else {
                    this.disabled = false;
                    const now = this.datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm', 'UTC+1');

                    const errorMessage = this.translationFacade.instant('error.signature_document_saving', { param1: err.error.error_description, date: now });
                    this.dialogRef.componentInstance.data = {
                        error: {
                            message: errorMessage,
                            button_text: 'global.cancel',
                        },
                    };
                }
            });
    }

    clearSignaturePad(): void {
        this.signaturePad.clear();
        this.canvasDirty = false;
        this.checkDisabledField();
    }

    drawComplete(): void {
        let canvas = (this.signaturePad as any).elementRef.nativeElement.querySelector('canvas');
        canvas = this.trim(canvas);

        this._signatureBlob = blobUtil.dataURLToBlob(canvas.toDataURL('image/jpg'));
        this.data.set('signature', this._signatureBlob, 'signature.jpg');
    }

    drawStart(): void {
        this.canvasDirty = true;
        this.checkDisabledField();
    }

    checkInput(): void {
        this.inputDirty = this.cityOfSignature.length > 0;
        this.checkDisabledField();
    }

    openDialog(): void {
        this.dialogRef = this.dialog.open(ContractDocumentCreationModalComponent, {
            width: '500px',
            disableClose: true,
            data: {
                text: 'Vertragsdokumente',
            },
        });
    }

    private checkDisabledField(): void {
        this.disabled = !(this.canvasDirty && this.inputDirty);
    }

    private trim(c: HTMLCanvasElement) {
        const ctx = c.getContext('2d');
        const copy = document.createElement('canvas').getContext('2d');
        const pixels = ctx.getImageData(0, 0, c.width, c.height);
        const l = pixels.data.length;
        let i;
        const bound = {
            top: null,
            left: null,
            right: null,
            bottom: null,
        };
        let x;
        let y;

        for (i = 0; i < l; i += 4) {
            if (pixels.data[i + 3] !== 0) {
                x = (i / 4) % c.width;
                y = ~~((i / 4) / c.width);

                if (bound.top === null) {
                    bound.top = y;
                }

                if (bound.left === null) {
                    bound.left = x;
                } else if (x < bound.left) {
                    bound.left = x;
                }

                if (bound.right === null) {
                    bound.right = x;
                } else if (bound.right < x) {
                    bound.right = x;
                }

                if (bound.bottom === null) {
                    bound.bottom = y;
                } else if (bound.bottom < y) {
                    bound.bottom = y;
                }
            }
        }

        const trimHeight = bound.bottom - bound.top;
        const trimWidth = bound.right - bound.left;
        const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

        copy.canvas.width = trimWidth;
        copy.canvas.height = trimHeight;
        copy.putImageData(trimmed, 0, 0);

        return copy.canvas;
    }

}
