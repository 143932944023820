import {
    IContractTypeDto,
    IInhouseQuoteCalculationDto,
    IInhouseQuoteDto,
    ISaleAndLeaseBackDto,
} from '@abcfinlab/api/global';
import { TranslationFacade } from '@abcfinlab/core';
import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

/**
 * Component to summarize the quote information before the user potentially signs the contract
 *
 * @slots
 * - salesContact:
 *      Section to provide additional contact informations to the user
 */

@UntilDestroy()
@Component({
    selector: 'l7-confirmation-summary',
    templateUrl: './confirmation-summary.component.html',
    styleUrls: ['./confirmation-summary.component.scss'],
    standalone: false,
})
export class ConfirmationSummaryComponent {

    @Input() quoteSummary: IInhouseQuoteDto = null;
    protected readonly iContractTypeDto = IContractTypeDto;

    constructor(private readonly _translationFacade: TranslationFacade) { }

    /**
     * Convinient method to get the vendor name
     */
    get vendorName(): string {
        return this.quoteSummary.objects[0].vendor.name;
    }

    /**
     * Convinient method to get the vendor address part 1
     */
    get vendorAddress1(): string {
        return `${this.quoteSummary.objects[0].vendor.street} ${this.quoteSummary.objects[0].vendor.house_number}`;
    }

    /**
     * Convinient method to get the vendor address part 2
     */
    get vendorAddress2(): string {
        return `${this.quoteSummary.objects[0].vendor.postcode} ${this.quoteSummary.objects[0].vendor.city}`;
    }

    /**
     * Convinient method to get the lessee
     */
    get lessee(): Record<string, any> {
        return this.quoteSummary.lessee;
    }

    /**
     * Convinient method to get the lessee name
     */
    get lesseeName(): string {
        return this.lessee.name;
    }

    /**
    * Convinient method to get the lessee address part 1
    */
    get lesseeAddress1(): string {
        return `${this.lessee.street} ${this.lessee.house_number}`;
    }

    /**
    * Convinient method to get the lessee address part 2
    */
    get lesseeAddress2(): string {
        return `${this.lessee.postcode} ${this.lessee.city}`;
    }

    /**
     * Convinient method to get the leasing object
     */
    get leasingObject(): Record<string, any> {
        return this.quoteSummary.objects[0];
    }

    /**
     * Convinient method to get the quote calculation
     */
    get calculation(): IInhouseQuoteCalculationDto {
        return this.quoteSummary.inhouseQuoteCalculation;
    }

    /**
     * Convinient method to check if the additional costs container should be rendered
     * @todo:
     * - find a better solution for agregating decisions if the element should be shown. Actually adopted from
     *   the older "quote-summary-container" component.
     */
    get hasAdditionalCosts(): boolean {
        return this.quoteSummary.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceValue > 0;
    }

    /**
     * Convinient method to get contract type
     */
    get contractType(): IContractTypeDto {
        return this.quoteSummary.inhouseQuoteCalculation.contractType;
    }

    /**
     * Convinient method to get the translated contract type / potentially with "slb" extra
     */
    get contractTypeText(): string {
        let output = this._translationFacade.instant(`states.contract_type.${this.quoteSummary.inhouseQuoteCalculation.contractType}`);
        if (!!this.slbInformation?.code && this.quoteSummary.inhouseQuoteCalculation.contractType !== IContractTypeDto.Mkn) {
            output += ` + SLB (${this._translationFacade.instant(`slb.codes.new.${this.slbInformation.code}`)})`;
        }
        if (!!this.slbInformation?.code && this.quoteSummary.inhouseQuoteCalculation.contractType === IContractTypeDto.Mkn) {
            output += ` + SMB (${this.slbInformation.code})`;
        }
        return output;
    }

    /**
     * Convinient method to get the translated object condition
     */
    get condition(): string {
        return this._translationFacade.instant(`condition.${this.leasingObject.condition}`);
    }

    /**
     * Convinient method to get access to the "sales and lease back" (slb) information
     */
    get slbInformation(): ISaleAndLeaseBackDto {
        return this.quoteSummary.saleAndLeaseBack;
    }

}
