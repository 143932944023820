<form [formGroup]="conclusionForm">
  <div>
    <mat-checkbox formControlName="successfulIdentificationNaturalPerson" id="checkbox_is-natural-person">Ich habe den Vertragspartner,
      gegebenenfalls eine für ihn auftretende Person, vor Begründung der Geschäftsbeziehung (Vertrag) identifiziert. Das
      Original des Ausweisdokumentes, welches mir die anwesende natürliche Person vorgelegt hat, habe ich vollständig
      und lesbar fotografiert. </mat-checkbox>
  </div>
  <hr>
  <div>
    <mat-radio-group formControlName="checkPersonalConfirmation" color="primary" id="radio_handling-on-his-own">
      <mat-radio-button value="confirm">
        Auf Befragung gab der Vertragspartner an,
        auf eigene Rechnung zu handeln.
      </mat-radio-button>
      <mat-radio-button value="deny" id="radio_not-handling-on-his-own">
        Es gibt (einen) abweichende/n wirtschaftlich Berechtigte/n.
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <hr>
</form>
