/* libs/legacy/gwg/src/lib/confirmation/confirmation-summary/confirmation-summary.component.scss */
.bg-white {
  background-color: #ffffff;
}
.h-full {
  height: 100%;
}
.mt-8 {
  margin-top: 8px;
}
.mt-24 {
  margin-top: 24px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-24 {
  margin-bottom: 24px;
}
.my-16 {
  margin: 16px 0;
}
.my-32 {
  margin: 32px 0;
}
.p-24 {
  padding: 24px;
}
.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.py-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}
.pr-12 {
  padding-right: 12px;
}
.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.px-24 {
  padding: 0 24px;
}
.border {
  border: 1px solid var(--l7-grey-100-color);
}
.border.rounded {
  border-radius: 6px;
}
.text-sm {
  font-size: smaller;
}
.text-lg {
  font-size: large;
}
.text-xl {
  font-size: x-large;
}
.font-medium {
  font-weight: 600;
}
.font-semi-bold {
  font-weight: 700;
}
.text-grey-400 {
  color: var(--l7-grey-400-color);
}
/*# sourceMappingURL=confirmation-summary.component.css.map */
